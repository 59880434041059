/*==========================================
            Responsive-Area
==========================================*/

/* / / Display SIze 320 / / */
@media only screen and (max-width:359px) {
    .catagore__Box_banner .rm-hero-slider {
        height: 30vh !important;
    }
}

/* / / Display SIze 360 / / */
@media only screen and (max-width:599px) {
    .catagore__Box_banner .rm-hero-slider {
        height: 30vh !important;
    }
}

/* / / Display SIze 600 / / */
@media only screen and (max-width:639px) {
    .catagore__Box_banner .rm-hero-slider {
        height: 30vh !important;
    }
}

/* / / Display SIze 640 / / */
@media only screen and (max-width: 767px) {
    .top_header_animishon {
        display: none;
    }
}

/* / / Display SIze 768 / / */
@media only screen and (max-width: 799px) {
    .top_header_animishon {
        display: none;
    }

    .header_bottom_area {
        display: none !important;
    }

    .logo_header {
        display: none !important;
    }

    .header_search {
        margin-top: 0;
    }

    .catagore__Box_banner .rm-hero-slider {
        height: 32vh !important;
    }

    .catagories__hdr__nme {
        display: none;
    }

    .footer__fixt__box {
        display: block;
    }

    .header_right_area_item {
        display: none;
    }

    .header_right_area_item {
        margin-bottom: 15px;
        margin-top: 5px;
    }

    .header_right_area_item .r__svg {
        float: left;
        font-size: 22px;
        margin-right: 5px;
    }

    .header_right_area_item p {
        padding: 0;
    }

    .header_search {
        display: none;
    }

    .movlile__rasponsive {
        display: block !important;
        border-bottom-left-radius: 30px;
        border-top-left-radius: 30px;
    }
    .banner {
        display: none;
    }
    .dashBordMenu_box ul li a {
        padding: 10px;
    }
    .active__box_area__table {
        display: none;
    }
    .movile__rspnsive__Header_view {
        display: block;
    }
    .overlay-container {
        display: none;
    }
    .login_area .sign-in-container {
        width: 100% !important;
    }
    .login_area {
        padding: 0px !important;
    }
    .creact__new__account__btn {
        display: block;
    }
    .creact__new__account__btn {
        display: none;
        color: #d61c22 !important;
        font-weight: 600;
    }
    .creact__new__account__btn:hover {
        color: #222 !important;
    }
    .accout___creact___account____area {
        width: 100%;
        display: block;
        padding: 35px;
    }
    .accout___creact___account____area .from_home_area  {
        padding: 0px !important;
    }
    .login_area .container {
        box-shadow: none !important;
    }
    .logo__icon__footer {
        position: absolute;
        width: 30px;
        left: 45%;
        top: -18px;
        width: 40px;
        height: 40px;
        background: #fff;
        border: 1px solid #2222;
        padding: 7px;
        border-radius: 50px;
        padding-top: 10px;
        cursor: pointer;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 2px 10px rgba(0, 0, 0, 0.10);
    }
    .dashBordMenu_box ul li {
        width: 100%;
        float: left;
        display: block;
        padding-right: 40px;
    }
    .cart_span_number {
        position: absolute;
        width: 25px;
        height: 25px;
        background: #f00;
        color: #fff;
        border-radius: 30px;
        line-height: 22px;
        font-size: 9px;
        top: -9px;
        right: -22px;
    }
    .box__one__order__details {
        width: 100%;
    }
    .order__imgesss {
        width: 100%;
    }
    .box__one__order__details_h2 {
        width: 100% !important;
        padding-top: 20px;
        float: left;
    }
    .box__one__order__details h5 {
        width: 100% !important;
        padding-top: 10px;
    }
    .box__one__order__details_h5_10 {
        width: 100% !important; 
    }
    .box__one__order__details_h5_15 {
        width: 100% !important;
    }
    .box__one__order__details__last {
        background: #fff !important;
        width: 100%;
        float: left;
        padding: 0px;
        border-radius: 5px;
        margin-left: 0px;
    }
    .box__one__order__details h3 {
        float: left;
    }
    .dddddddd {
        width: 100%;
    }
}

/* / / Display SIze 900 / / */
@media only screen and (max-width: 899px) {}

/* / / Display SIze 890 / / */
@media only screen and (max-width: 979px) {
    .footer_area_meddil_box {
        float: none;
        text-align: center;
    }

    .social_icon_area {
        display: flex;
        justify-content: center;
    }

    #container_animishon {
        height: 24px !important;
        background-size: contain !important;
    }
}

/* / / Display SIze 980 / / */
@media only screen and (max-width: 1023px) {
    .logo_header {
        width: 25%;
        margin-top: 9px;
        margin: auto;
        display: block;
    }

    .header_b_midell {
        width: 100%;
        float: left;
        display: none;
    }

    .footer-border p {
        padding-top: 0px !important;
    }

    .subfooter {
        width: 100%;
        padding: 13px 0;
        margin-bottom: 50px;
        font-size: 11px;
    }

    .header_b_left .header_right_area_item {
        display: none;
    }

    .header_b_midell ul li a {
        padding: 8px 6px !important;
    }

    .catagore__Box_banner_menu {
        display: none;
    }

    .newsletter_input {
        width: 100%;
    }

    .mobile__footer_area {
        display: block;
    }
}

/* / / Display SIze 1024 / / */
@media only screen and (max-width: 1279px) {
    #container_animishon {
        height: 32px !important;
        background-size: contain !important;
    }

    .header_b_midell ul li a {
        padding: 8px 10px;
        font-size: 12px;
    }

    .header_right_area_item_order {
        display: none;
    }

    .home_banner__add {
        width: 100%;
    }

    .ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title {
        height: 25px;
        line-height: 25px;
    }

    .header_right_area {
        display: none;
    }
}

/* / / Display SIze 1280 / / */
@media only screen and (max-width:1365px) {
    .search_all_area .ant-input-affix-wrapper {
        width: 60% !important;
    }
}


/* / / Display SIze 1366 / / */
@media only screen and (max-width: 1919px) {
    .header_right_area_item {
        padding: 0 15px;
    }

}