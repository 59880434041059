/*======================================================================
	Project Name: E-one
	Creator     : Dulal Hossain
	E-mail		: dulalhossain34220@gmail.com
	Date        : june/05/2022
========================================================================*/

/*===========@Global Styles ============*/
body {
	font-family: 'Lato', sans-serif !important;
	color: #222;
	font-size: 14px;
}

.App {
	background: #f2f2f2;
}

* {
	margin: 0;
	padding: 0;
	outline: none
}

img,
fieldset {
	border: 0
}

a {
	text-decoration: none
}

ul,
li {
	padding: 0px;
	margin: 0px;
	list-style: none
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
	padding: 0;
	font-weight: normal;
}

.img-fit {
	max-height: 100%;
	width: 100%;
	object-fit: cover;
}

/* HEADER AREA  */
.header__area {
	width: 100%;
	float: left;
	display: block;
	background: #fff;
}

.logo_header {
	width: 80%;
	margin-top: 9px;
}

.header_search {
	width: 100%;
	margin-top: 12px;
}

.header_search .ant-space {
	width: 100%;
}

.header_search .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	background-color: #fff !important;
	border: 2px solid #d61c22 !important;
	box-shadow: none;
	border-right: transparent !important;
}

.header_search .ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border-radius: 25px 0 0 25px;

}

.header_search .ant-input-group-addon {
	border: none !important;
}

.header_search .ant-input {
	border: 2px solid #d61c22 !important;
	border-right: transparent;
	padding: 8px 11px;
	color: rgba(106, 106, 106, 0.85) !important;
}

/* background: #d61c22;
color: #fff; */
.header_search .ant-input-group-addon:last-child {
	background: #d61c22 !important;
	color: #fff !important;
	border-radius: 0px 25px 25px 0;
	font-weight: 600;
	padding: 0 25px;
}

.header_search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	height: 42px !important;
	padding: 0 25px !important;
}

.header_search .ant-select-single .ant-select-selector .ant-select-selection-item {
	line-height: 35px !important;
}

.header_right_area {
	width: 100%;
	float: left;
	display: block;
	padding: 5px 0;
	font-size: 15px;
	text-align: center;
	position: relative;
	z-index: 999;
}

.header_right_area button {
	background: transparent;
	/* border: none !important; */
}

.header_right_area svg {
	font-size: 27px;
	color: #575656;
	margin-right: 5px;
}

.header_right_area_item {
	width: auto;
	float: left;
	display: block;
	padding: 0 25px;
	cursor: pointer;
	position: relative;
}

.header_right_area_item p {
	font-weight: 500;
	line-height: 15px;
	color: #575656;
}

.login-icon-unsignavatar,
.order-icon-unsignavatar,
.cart-icon-unsignavatar {
	background-repeat: no-repeat;
	background-image: url('../images/allicon.png');
	display: inline-block;
	vertical-align: inherit;
}

.login-icon-unsignavatar {
	width: 28px;
	height: 28px;
}

.login-icon-unsignavatar {
	background-position: -313px -144px;
}

.order-icon-unsignavatar {
	background-position: -208px -191px;
	width: 23px;
	height: 20px;
}

.cart-icon-unsignavatar {
	background-position: -242px -191px;
	width: 23px;
	height: 20px;
}

.login__h_ {
	float: left;
	padding-top: 6px;
	padding-left: 5px;
	font-size: 11px;
	text-align: left;
}

.login-icon-padding {
	margin-top: 10px;
	float: left;
}

.login__h_ span {
	font-size: 15px;
	font-weight: 600;
	padding-top: 3px;
	float: left;
}






.header_right_area .menu-item {
	width: auto;
	float: left;
	perspective: 2000px;
}

.header_right_area .sub-menu {
	position: absolute;
	top: 56px;
	padding: 15px;
	background-color: #fff;
	border-radius: 6px;
	transform-origin: 50% -30px;
	width: 230px;
	right: 12px;
	text-align: left;
	/* box-shadow: 0 2px 5px 0 #AAA; */
}

.header_right_area .sub-menu::before {
	content: ' ';
	box-sizing: content-box;
	width: 0;
	height: 0;
	position: absolute;
	top: -18px;
	right: 24px;
	padding: 0;
	border-bottom: 9px solid #fed1d8;
	border-top: 9px solid transparent;
	border-left: 9px solid transparent;
	border-right: 9px solid transparent;
	display: block;
}



.header_right_area .sub-menu-background {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transform-origin: 0 0;
	box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
		0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

/* .sub-menu-container {
  }
   */
.header_right_area .sub-menu-item {
	margin-bottom: 5px;
}

.sub-menu-container p {
	width: 100%;
	float: left;
	margin-bottom: 12px;
	font-size: 14px;
	color: #333;
}

.header_sing_in_btn {
	width: 100%;
	background: #d61c22 !important;
	color: #fff;
	padding: 2px 0;
	margin-bottom: 10px;
	border-radius: 50px;
	border: 1px solid #d61c22 !important;
}

.header_sing_in_btn:hover {
	background: #222;
}

.header_sing_in_btnjoin {
	background: transparent !important;
	color: #222;
	border: 1px solid #d61c22 !important;
}

.sc-hd-ms-login-title {
	font-size: 10px;
	color: #999;
	letter-spacing: 0;
	text-align: center;
	margin: 16px 0;
}

.sc-hd-ms-login-title-line {
	display: inline-block;
	width: 48px;
	height: 1px;
	background: #D8D8D8;
	margin: 4px 8px;
}

.sc-hd-ms-login-title-line {
	display: inline-block;
	width: 48px;
	height: 1px;
	background: #D8D8D8;
	margin: 4px 8px;
}

.header_bottom_area {
	width: 100%;
	float: left;
	display: block;
	border-top: 1px solid #E6E7EB;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, .1);
}


.header_b_left {
	position: relative;
	z-index: 999;
}

.header_b_left .menu-item {
	width: auto;
	float: left;
	perspective: 2000px;
	width: 100%;
}

.header_b_left .sub-menu {
	position: absolute;
	top: 20px;
	padding: 15px;
	background-color: #fff;
	border-radius: 6px;
	transform-origin: 50% -30px;
	width: 270px;
	/* right: 12px; */
	text-align: left;
	/* box-shadow: 0 2px 5px 0 #AAA; */
}

.header_b_left .sub-menu-background {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transform-origin: 0 0;
	box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
		0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.c_down_arrao {
	float: right;
	margin-right: 8px;
	margin-top: 3px;
}

/* .sub-menu-container {
  }
   */
.header_b_left .sub-menu-item {
	margin-bottom: 5px;
}

/* .header_b_left {
	width: 100%;
	padding: 5px 0;
} */

.header_b_left .menu-item svg {
	font-size: 16px;
	margin-right: 4px;
}

.header_b_left .header_right_area_item {
	border-right: 1px solid #2222;
	margin-top: 9px;
	margin-bottom: 9px;
	width: 100%;
	padding: 0;
}

.header_b_midell {
	width: 100%;
	float: left;
	display: block;
}

.header_b_midell ul li a {
	width: auto;
	float: left;
	display: block;
	color: #222;
	padding: 8px 13px;
}

/* BANNER AREA  */

.home_banner_area {
	width: 100%;
	overflow: hidden;
	position: relative;
	background: #F2F3F7 url("https://i.alicdn.com/img/imgextra/i4/O1CN01vUISwo1Gh7OIwTp0w_!!6000000000653-2-tps-1920-317.png") no-repeat center 0;
	/* height: 400px; */
}

.home_banner__add {
	width: auto;
	margin: auto;
	display: block;
}

.slider__area {
	width: 100%;
	float: left;
	display: block;
	background: #fff;
	padding: 15px;
	margin-bottom: 30px;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
	border-right: none;
}

.catagore__Box_banner {
	width: 100%;
	float: left;
	display: block;
}

.catagore__Box_banner h3 {
	font-size: 15px;
	padding: 0 20px;
	font-weight: 600;
	border-bottom: 1px solid #76767621;
	padding-bottom: 15px;
}

.catagore_width {
	width: 100% !important;
}

/* THREE CATEGOREBOX */
.three_catagori_box {
	width: 100%;
	float: left;
	display: block;
	background: #fff;
	padding: 15px;
	border-radius: 5px;
	margin-bottom: 30px;
}

.full_area {
	width: 100%;
	float: left;
	display: block;
}

.three_catagori_box p svg {
	font-size: 24px;
	margin-right: 5px;
}

.three_catagori_single_images {
	position: relative;
	margin: 3px;
	text-align: center;
	cursor: pointer;
}

.three_catagori_single_images::after {
	position: absolute;
	content: "";
	background: rgba(0, 0, 0, .03);
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	border-radius: 4px;
}

.three_catagori_box__full {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.three_catagori_box p {
	margin-bottom: 6px;
	font-weight: 600;
}

.three_catagori_box p a {
	float: right;
	font-size: 13px;
	color: #d61c22;
}

.three_catagori_single_images_text {
	width: 100%;
	display: block;
	text-align: center;
	padding-top: 6px;
}

.three_catagori_single_images_text h5 {
	width: 100%;
	text-align: center;
	font-size: 13px;
	font-weight: 600;
}

.three_catagori_single_images_text h4 {
	width: 100%;
	text-align: center;
	font-size: 13px;
	padding-top: 2px;
}


/* EONE MAIL  */

.e_one_s_box {
	width: 100%;
	float: left;
	display: block;
	position: relative;
	cursor: pointer;
	transition: all .3s;
}

.e_one_s_box:hover {
	box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .15);
}

.logo__mail {
	width: 60px !important;
	border: 1px solid rgba(34, 34, 34, .13333333333333333);
	position: absolute;
	bottom: 63px;
	left: 34%;
}

.e_one_s_box_text {
	width: 100%;
	text-align: center;
	float: left;
	padding: 15px 0;
	border: 1px solid rgba(34, 34, 34, .13333333333333333);
	border-top: none;
}

.e_one_s_box_text h3 {
	font-size: 13px;
	font-weight: 600;
	margin-top: 20px;
}

.e_one_s_box_text p {
	font-size: 12px;
	color: #9e9e9e;
}

/* JUST FOR YOU  */
.product_hone_box_text p {
	color: #222;
	font-size: 13px;
	line-height: 17px;
	font-weight: normal;
	height: 50px;
	/* vertical-align: bottom; */
	/* display: flex; */
	display: table-cell;
	vertical-align: bottom;
}

.price__ {
	width: 100%;
	padding-top: 5px;
	color: #222;
	font-weight: 600;
	letter-spacing: .5px;
}

.price__cart__btn {
	float: right;
	background: #f00;
	border: none;
	color: #fff;
	width: 25px;
	height: 25px;
	border-radius: 30px;
	line-height: 0;
	transition: all .3s;
}

.price__cart__btn:hover {
	background: #222;
	color: #fff;
}

.min__order {
	color: #666;
	font-size: 13px;
}

.cart_span_number {
	position: absolute;
	width: 25px;
	height: 25px;
	background: #f00;
	color: #fff;
	border-radius: 30px;
	line-height: 22px;
	font-size: 9px;
	top: 27px;
	right: -10px;
}

.brack_cumes_area p a:last-child {
	color: #222;
	font-weight: bold;
}

.brack_cumes_area p a:last-child span {
	display: none;
}

.loadMore__btn {
	text-align: center;
	margin: auto;
	display: block;
	padding: 7px 20px;
	margin-top: 22px;
	border: 1px solid #f6989b;
	background: #ffd2d3;
	font-weight: 600;
	letter-spacing: .6px;
	font-size: 12px !important;
}

/* FOOTER AREA  */
.subcrub_area {
	background-image: url("../images/subscribe2.png");
	background-size: cover;
	background-position: center center;
	display: block;
	padding-top: calc(25px + (40 - 25) * ((100vw - 320px) / (1920 - 320)));
	padding-bottom: calc(25px + (40 - 25) * ((100vw - 320px) / (1920 - 320)));
	background-color: #ee4b50;
	float: left;
	width: 100%;
	margin-bottom: 40px;
}

.subcrub_area img {
	width: 60px;
	float: left;
}

.subcrub_txt {
	width: auto;
	float: left;
	margin-left: 10px;
}

.subcrub_txt p {
	color: #ffffff;
	text-transform: capitalize;
	font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
	font-weight: 500;
}

.subcrub_txt h3 {
	color: #ffffff;
	font-weight: 700;
	margin-bottom: 0;
	font-size: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
	text-transform: uppercase;
	line-height: 30px;
}

.subcrub_area .ant-input {
	padding: 8px 11px;
}

.subcrub_area .ant-btn-icon-only {
	width: 100px !important;
	height: 40px !important;
	background: #000000 !important;
	color: #fff !important;
}

.footer_area {
	/* background: #445268; */
	/* width: 100%; */
	/* float: left; */
	/* display: block; */
	/* padding-top: 50px; */
	padding-top: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
	padding-bottom: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
	background-color: #ffffff;
}

.footer_area_top p {
	text-align: center;
	color: #fff;
	letter-spacing: .6px;
	line-height: 25px;
}

.footer_area_meddil_box {
	width: 100%;
	float: left;
	display: block;
}

.footer_area_meddil_box h3 {
	color: #222;
	font-size: 16px;
	/* letter-spacing: .6px; */
	text-transform: capitalize;
	font-weight: 600;
	padding-bottom: 10px;
	/* margin-top: 15px; */
}

.footer_area_meddil_box img {
	width: 60%;
}

a::before {
	transition: all .3s ease-in-out;
}

.footer_area_meddil_box ul li a {
	width: 100%;
	color: #8d8d8d;
	padding: 5px 0;
	float: left;
	transition: all .3s ease-in-out;
	font-size: 13px;
}

.footer_area_meddil_box ul li a::before {
	content: "";
	display: inline-block;
	width: 0px;
	height: 3px;
	vertical-align: middle;
	background-color: #8d8d8d;
}

.footer_area_meddil_box ul li a:hover::before {
	width: 4px;
	margin-right: 3px;
}

.footer_area_meddil_box p {
	color: #8d8d8d;
	font-size: 12px;
	line-height: 25px;
	padding-top: 15px;
}

.newsletter_input {
	width: 60%;
	margin-top: 15px !important;
	float: right;
}

.social_icon_area {
	width: 100%;
	float: left;
	display: block;
	padding: 10px 0;
}

.social_icon_area svg {
	margin-right: 8px;
	padding: 6px;
	float: left;
	width: calc(28px + (35 - 28) * ((100vw - 320px) / (1920 - 320)));
	height: calc(28px + (35 - 28) * ((100vw - 320px) / (1920 - 320)));
	border-radius: 50%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #f7f7f7;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.subfooter {
	width: 100%;
	padding: 20px 0;
}

.footer-border p {
	width: auto;
	float: left;
	padding-top: 10px;
}

.footer-border img {
	width: 20%;
	float: right;
}

.footer_area_bottom_box {
	width: 100%;
	float: left;
	display: block;
	background: #3f4b5e;
	margin-top: 20px;
	padding: 20px;
}

.prayment______img img {
	width: 50%;
	float: right;
}

.developer__name p {
	font-weight: 600;
	font-size: 14px;
	padding: 10px 0;
	letter-spacing: .6px;
	color: #dee9fb;
}

/* PRODUCT CATEGORE AREA  */
.product_categories_area {
	width: 100%;
	float: left;
	display: block;
}

.product_categories_left_sidbar {
	width: 100%;
	float: left;
	display: block;
	padding: 15px;
	background: #f6f6f6;
	margin-top: 40px;
	margin-bottom: 40px;
	border-radius: 5px;
}

.product_categories_left_sidbar h3 {
	font-size: 16px;
	font-weight: 600;
	padding-bottom: 3px;
}

.product_categories_left_sidbar p {
	padding-bottom: 25px;
}

.checkbox__area {
	width: 100%;
	float: left;
	display: block;
	/* padding-top: 10px; */
	margin-bottom: 15px;
}

.checkbox__area h5 {
	font-size: 14px;
	font-weight: 600;
	padding-bottom: 5px;
}

.checkbox__area ul li {
	padding-bottom: 5px;
}

.checkbox__area .ant-checkbox+span {
	padding-right: 8px;
	padding-left: 8px;
	font-size: 13px;
}

.brack_cumes_area {
	width: 100%;
	float: left;
	display: block;
	margin-top: 40px;
	padding-left: 15px;
}

.brack_cumes_area h3 {
	font-size: 16px;
	font-weight: 600;
}

.brack_cumes_area p {
	color: #222;
	padding: 2px 0;
	font-size: 13px;
}

.brack_cumes_area p a {
	color: #ababab;
}

/* PRODUCT DETAILS  */
.brack_cumes_details {
	/* margin-top: 0px !important; */
	/* padding-left: 0px !important; */
	padding: 15px;
	border-bottom: 1px solid #2222;
	margin-bottom: 0px;
	background: #fff;
	margin-top: 14px;
}

.react-slider__picture {
	min-height: auto !important;
}

.product_details_box_area {
	width: 100%;
	display: block;
	background: #fff;
	padding: 20px;
}

.product_details_contant {
	font-size: 20px;
	font-weight: 600;
	line-height: 24px;
	width: 100%;
}

.product_details_contant h3 {
	font-size: 20px;
	font-weight: 600;
	line-height: 30px;
	width: 100%;
}

.product_details_contant span {
	font-weight: 400;
	font-size: 13px;
}

.product_details_contant img {
	width: 100%;
	padding: 10px 0;
}

.availability__ {
	width: 100%;
	float: left;
	display: block;
	font-size: 14px;
}

.price del {
	font-size: 18px;
	color: #f00;
}

.size_area {
	width: 100%;
	float: left;
	padding-top: 15px;
}

.size_area p {
	float: left;
	margin-right: 15px;
	margin-top: 10px;
	font-size: 14px;
	letter-spacing: .6px;
	width: 100px;
}

.aiz-megabox {
	position: relative;
	cursor: pointer
}

.aiz-megabox input {
	position: absolute;
	z-index: -1;
	opacity: 0
}

.aiz-megabox .aiz-megabox-elem {
	border: 1px solid #e2e5ec;
	border-radius: .25rem;
	-webkit-transition: all .3s ease;
	transition: all .3s ease;
	border-radius: .25rem
}

.aiz-megabox>input:checked~span .aiz-rounded-check:after,
.aiz-megabox>input:checked~span .aiz-square-check:after {
	visibility: visible;
	opacity: 1
}

.aiz-megabox>input:checked~.aiz-megabox-elem,
.aiz-megabox>input:checked~.aiz-megabox-elem {
	border-color: #f00;
}

.size_area b {
	font-size: 14px;
	font-weight: 400;
	letter-spacing: .6px;
}

.size_area label {
	margin-right: 10px;
}

.size-30px {
	height: 30px;
	width: 30px;
}

.price_qantity input {
	width: 45px;
	background: #a8a8a821 !important;
	padding: 5px 0;
}

.price_qantity button {
	background: #b61b20 !important;
	border: none;
	color: #fff;
	padding: 5px 10px;
	border-radius: 3px;
}

.price_qantity {
	width: auto;
	float: left;
}

.size_area h6 {
	float: left;
	padding: 8px 10px;
	font-size: 14px;
	/* color: #6a6a6a; */
	opacity: .4;
}

.order_button {
	width: 100%;
}

.order_button button {
	background: #63c7e9;
	border: none;
	margin-right: 14px;
	margin-top: 26px;
	padding: 5px 25px;
	color: #fff;
	font-size: 14px;
	letter-spacing: .6px;
}

.delivery_ch_box {
	width: 100%;
	float: left;
	display: block;
	background: #fafafa;
	padding: 10px;
}

.delivery_charg_box_list {
	width: auto;
	display: flex;
	padding-top: 10px;
	border-bottom: 1px solid #6f6f6f21;
	padding-bottom: 10px;
}

.delivery_ch_box h5 {
	font-size: 13px;
}

.delivery_charg_box_list p {
	max-width: 180px;
	word-break: break-word;
	line-height: 16px;
	font-size: 12px;
}

.delivery_charg_box_list svg {
	width: 30px;
	font-size: 21px;
	margin-top: 5px;
	margin-right: 5px;
}

.delivery_charg_box_list button {
	background: transparent;
	border: none;
	margin-left: 5px;
	line-height: 0;
	font-weight: 600;
	color: #b81b20;
}

.delivery_charg_box_list_two {
	width: 100% !important;
}

.delivery_charg_box_list_two p {
	width: 100% !important;
}

.delivery_charg_box_list_two h3 {
	font-size: 13px;
	width: 100%;
}

.delivery_charg_box_list_two span {
	opacity: .6;
}

.delivery_charg_box_list_two b {
	float: right;
	opacity: 1;
}

.delivery_ch_box h6 {
	opacity: .6;
	font-size: 13px;
}

.delivery_ch_box a {
	color: #222;
	font-weight: 600;
	font-size: 13px;
}

.visit_store_link {
	width: 100%;
	float: left;
	text-align: center;
	border-top: 1px solid #2222;
	padding-top: 5px;
	margin-top: 10px;
}

.brack_cumes_details h5 {
	font-size: 14px;
	font-weight: 600;
}

.product__descrip_list ul {
	width: 50%;
	float: left;
	display: block;
}

.product__descrip_list ul li {
	padding-bottom: 5px;
}

.product__descrip_list ul li svg {
	font-size: 18px;
	margin-right: 5px;
}

.mobile__footer_area {
	display: none;
}

/* LOGIN AREA  */
.login_area {
	width: 100%;
	float: left;
	display: block;
	padding: 60px 0;
	background: #f6f5f7;
}

.login_area p {
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px;
}

.login_area span {
	font-size: 12px;
}

.login_area a {
	color: #333;
	font-size: 14px;
	text-decoration: none;
	margin: 15px 0;
}

.login_area button {
	border-radius: 20px;
	border: 1px solid #d61c22;
	background-color: #d61c22;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
}

.login_area button:active {
	transform: scale(0.95);
}

.login_area button:focus {
	outline: none;
}

.login_area button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}

.login_area form {
	/* background-color: #FFFFFF; */
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 100%;
	text-align: center;
}

.login_area input {
	/* background-color: #eee; */
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
}

.login_area .container {
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 2px 10px rgba(0, 0, 0, 0.10);
	position: relative;
	overflow: hidden;
	width: 768px;
	max-width: 100%;
	min-height: 480px;
}

.login_area .form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}

.login_area .sign-in-container {
	left: 0;
	width: 50%;
	z-index: 2;
}

.login_area .container.right-panel-active .sign-in-container {
	transform: translateX(100%);
}

.login_area .sign-up-container {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

.login_area .container.right-panel-active .sign-up-container {
	transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	animation: show 0.6s;
}

@keyframes show {

	0%,
	49.99% {
		opacity: 0;
		z-index: 1;
	}

	50%,
	100% {
		opacity: 1;
		z-index: 5;
	}
}

.login_area .overlay-container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: transform 0.6s ease-in-out;
	z-index: 100;
}

.login_area .container.right-panel-active .overlay-container {
	transform: translateX(-100%);
}

.login_area .overlay {
	background: #FF416C;
	background: -webkit-linear-gradient(to right, #FF4B2B, #FF416C);
	background: linear-gradient(to right, #FF4B2B, #FF416C);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: #FFFFFF;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.login_area .container.right-panel-active .overlay {
	transform: translateX(50%);
}

.login_area .overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.login_area .overlay-left {
	transform: translateX(-20%);
}

.login_area .container.right-panel-active .overlay-left {
	transform: translateX(0);
}

.login_area .overlay-right {
	right: 0;
	transform: translateX(0);
}

.login_area .container.right-panel-active .overlay-right {
	transform: translateX(20%);
}

.login_area .social-container {
	margin: 20px 0;
}

.login_area .social-container a {
	border: 1px solid #DDDDDD;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	height: 40px;
	width: 40px;
}

/* CART AREA  */

.add_to_cart.right.open-side {
	right: 15px;
}

.add_to_cart.right {
	height: 100vh;
	width: 340px;
	top: 65px;
}

.add_to_cart.open-side {
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.add_to_cart {
	position: fixed;
	background-color: #ffffff;
	z-index: 9999;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.add_to_cart.right .cart-inner {
	height: 100vh;
	overflow: auto;
}

.add_to_cart .cart-inner {
	background-color: #ffffff;
	z-index: 9;
	position: relative;
	top: 0;
}

.add_to_cart.left .cart-inner .cart_media ul.cart_product,
.add_to_cart.right .cart-inner .cart_media ul.cart_product {
	height: calc(100vh - 335px);
	overflow-y: auto;
}

.add_to_cart .cart-inner .cart_media ul.cart_product li {
	position: relative;
	width: 100%;
	border: 1px solid #dddddd;
	padding: 10px;
	margin-bottom: 8px;
}

.add_to_cart .cart-inner .cart_media ul.cart_product li .media {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100%;
}

.media .media-body {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.add_to_cart .cart-inner .cart_media ul.cart_product li .media .media-body h6 {
	color: #00baf2;
	font-weight: 700;
	margin-bottom: 5px;
}

.add_to_cart .cart-inner .cart_media ul.cart_product li .media .media-body h6 span {
	color: #999999;
	text-decoration: line-through;
}

.add_to_cart .cart-inner .cart_media ul.cart_product .addit-box {
	padding-top: 5px;
}

.qty-box {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
}

.qty-box .input-group {
	-webkit-box-pack: unset;
	-ms-flex-pack: unset;
	justify-content: unset;
	width: unset;
}

.input-group {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	width: 100%;
}

.add_to_cart .cart-inner .cart_media ul.cart_product .qty-minus,
.add_to_cart .cart-inner .cart_media ul.cart_product .qty-plus {
	width: 25px;
	padding: 0;
	font-size: 10px;
}

.qty-box .input-group button {
	background-color: transparent;
	cursor: pointer;
	line-height: 1;
	background-color: #ffffff;
	font-family: themify;
	padding-left: 10px;
	padding-right: 10px;
	border: none;
}

.media {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.add_to_cart .cart-inner .cart_media ul.cart_product li .media .media-body h6 {
	color: #ff5359;
	font-weight: 700;
	margin-bottom: 5px;
}

.add_to_cart .cart-inner .cart_media ul.cart_product li .media .media-body h4 {
	color: #444444;
	text-transform: capitalize;
	margin-bottom: 5px;
	font-size: 13px;
	font-weight: 600;
}

.add_to_cart .cart-inner .cart_media ul.cart_product li .media img {
	width: 60px;
	height: auto;
}

/* VirtulTry */
.collection-banner-main {
	position: relative;
	overflow: hidden;
	cursor: pointer;
}

.collection-img {
	width: 100%;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.collection-banner-contain {
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.collection-banner-contain {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 50px;
}

.collection-banner-contain h3 {
	color: #00baf2;
	text-transform: capitalize;
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 0;
}

.collection-banner-contain h4 {
	color: #1c3481;
	font-weight: 600;
	font-size: 36px;
	text-transform: capitalize;
}

.collection-banner-contain .shop {
	margin-top: 25px;
}

.collection-banner-contain .shop a {
	color: #000000;
	text-transform: capitalize;
	color: #1c3481;
	font-weight: 700;
}

.collection-banner .collection-banner-main::after {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 2;
	display: block;
	content: '';
	width: 0;
	height: 0;
	background: rgba(255, 255, 255, 0.2);
	border-radius: 100%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	opacity: 0;
}

.collection-banner-main:hover .collection-img {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.qty-box .input-group {
	-webkit-box-pack: unset;
	-ms-flex-pack: unset;
	justify-content: unset;
	width: unset;
	border: 1px solid #dddddd;
}

.add_to_cart .cart-inner .cart_media ul.cart_product .qty-minus {
	width: 25px;
	padding: 0;
	font-size: 10px;
}

/* .qty-box .input-group button:first-child::before {
	content: "\e622";
} */
.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
	margin-left: -1px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.add_to_cart .cart-inner .cart_media ul.cart_product .qty-plus {
	width: 25px;
	padding: 0;
	font-size: 10px;
}

/* .qty-box .input-group button:last-child::before {
	content: "\e61a";
} */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

.input-group input {
	width: 45px !important;
	text-align: center;
	border-top: none;
	border-bottom: none;
}

.input-group .form-control {
	padding: .075rem .75rem !important;

}

.add_to_cart.left .cart-inner .cart_media .cart_total,
.add_to_cart.right .cart-inner .cart_media .cart_total {
	width: calc(320px - -16px);
	position: fixed;
	bottom: 0px;
	margin-bottom: 20px;
}

.add_to_cart .cart-inner .cart_media ul.cart_total {
	background-color: rgba(255, 96, 0, 0.05);
	padding: 15px;
}

.add_to_cart .cart-inner .cart_media ul.cart_total li {
	display: block;
	color: #444444;
	font-size: 16px;
	text-transform: capitalize;
	line-height: 1;
	margin-bottom: 10px;
}

.add_to_cart .cart-inner .cart_media ul.cart_total li span {
	float: right;
	text-transform: uppercase;
}

.add_to_cart .cart-inner .cart_media ul.cart_total li .buttons {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.cart_total .buttons .btn {
	text-transform: capitalize;
	font-size: 14px;
}

.btn-solid {
	font-size: 16px;
	padding: 11px 30px;
	font-weight: 700;
	color: #ffffff !important;
	background-color: #d61c22;
	position: relative;
	transition: all 0.3s;
	display: inline-block;
	line-height: 1;
	letter-spacing: 0.05em;
	border-radius: 5px;
	margin-top: 10px;
}

/* Cart Pages  */

.card .card-header {
	background-color: transparent;
	border-bottom: none;
	padding: 20px;
	position: relative;
}

.card-header:first-child {
	border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.table {
	width: 100%;
	margin-bottom: 0;
	color: #212529;
}

.card .card-block table tr {
	padding-bottom: 20px;
}

.table-card .card-block .table tr th:first-child {
	padding-left: 20px;
}

.table thead th {
	border-bottom: 1px solid #d6dde1;
}

.table> :not(:first-child) {
	border-top: none !important;
}

/* CHEACK YOU  */
.b-g-light {
	background-color: #f9f9f9;
}

.checkout-page .checkout-title {
	margin-bottom: 25px;
}

.checkout-page .checkout-title h3 {
	color: #333333;
	font-weight: 700;
	font-size: 24px;
}

.contact-page .theme-form {
	padding: 30px;
	background-color: #f1f4f7;
	border: 30px solid #ffffff;
}

.checkout-page .checkout-form .form-group {
	position: relative;
	margin-bottom: 25px;
}

.checkout-page .checkout-form .form-group label {
	color: #333333;
}

.contact-page .theme-form label {
	text-transform: capitalize;
	color: #333333;
	font-size: calc(12px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
	font-weight: 400;
	padding-bottom: 5px;
	letter-spacing: .6px;
}

.checkout-page .checkout-form input[type="text"] {
	width: 100%;
	padding: 0 22px;
	height: 45px;
	border: 1px solid #dddddd;
}

.contact-page .theme-form input {
	padding: 12px 25px;
	border-radius: 0;
	margin-bottom: 0;
}

.contact-page .theme-form {
	padding: 30px;
	background-color: #f1f4f7;
	border: 30px solid #ffffff;
}

.section-big-mt-space {
	margin-top: 50px;
}

.order-box {
	position: relative;
	margin-bottom: 15px;
}

.order-box .title-box {
	position: relative;
	padding-bottom: 25px;
	color: #444444;
	font-weight: 600;
	font-size: 22px;
	border-bottom: 1px solid #dddddd;
	margin-bottom: 20px;
}

.order-box .title-box span {
	position: relative;
	width: 35%;
	float: right;
	line-height: 1.2em;
}

.order-box .qty {
	position: relative;
	border-bottom: 1px solid #dddddd;
	margin-bottom: 30px;
}

.order-box .qty li {
	position: relative;
	display: block;
	font-size: 15px;
	color: #444444;
	line-height: 20px;
	margin-bottom: 20px;
}

.order-box .qty li span {
	float: right;
	font-size: 18px;
	line-height: 20px;
	color: #333333;
	font-weight: 400;
	width: 35%;
}

.order-box .sub-total {
	position: relative;
	border-bottom: 1px solid #dddddd;
	margin-bottom: 30px;
}

.order-box .sub-total li {
	position: relative;
	display: inline-block;
	font-size: 16px;
	font-weight: 600;
	color: #333333;
	line-height: 20px;
	margin-bottom: 20px;
	width: 100%;
}

.order-box .sub-total li .count {
	position: relative;
	font-size: 18px;
	line-height: 20px;
	color: #00baf2;
	font-weight: 400;
	width: 35%;
	float: right;
}

.order-box .total {
	position: relative;
	margin-bottom: 15px;
}

.order-box .total li {
	position: relative;
	display: block;
	font-weight: 400;
	color: #333333;
	line-height: 20px;
	margin-bottom: 10px;
	font-size: 18px;
}

.order-box .total li .count {
	position: relative;
	font-size: 18px;
	line-height: 20px;
	color: #222;
	font-weight: 400;
}

.payment-box {
	position: relative;
}

.payment-box .upper-box {
	position: relative;
}

.payment-box .payment-options {
	position: relative;
	margin-top: 20px;
}

.payment-box .payment-options li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 15px;
}

.payment-box .payment-options li .radio-option {
	position: relative;
}

.payment-box .payment-options li .radio-option input[type="radio"] {
	position: absolute;
	left: 0;
	top: 5px;
}

.contact-page .theme-form input {
	padding: 12px 25px;
	border-radius: 0;
	margin-bottom: 0;
}

.payment-box .payment-options li .radio-option label {
	position: relative;
	padding-left: 30px;
	text-transform: capitalize;
	color: #444444;
	cursor: pointer;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 0;
}

.payment-box .payment-options li .radio-option label .small-text {
	position: relative;
	display: none;
	font-size: 15px;
	line-height: 25px;
	font-weight: 300;
	color: #666666;
	margin-top: 10px;
}

/* DASHBOARD AREA  */
.dashboard_area {
	width: 100%;
	float: left;
	display: block;
	padding: 20px 0 50px 0;
	/* background: #fff; */
}

.dashboard_area h3 {
	font-size: 14px;
	font-weight: 600;
	padding-bottom: 3px;
	padding-top: 10px;
}

.dashboard_area .ant-tabs-tab {
	margin-top: 0px !important;
}

.dashboard_area .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #d61c22 !important;
	text-shadow: 0 0 0.25px currentcolor;
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-tab {
	border-bottom: 1px solid #2222 !important;
}

.dashboard_valid_ {
	width: 100%;
	float: left;
	display: block;
	padding-bottom: 10px;
}

.dashboard_valid_ h5 {
	font-size: 15px;
	padding-bottom: 10px;
}

.dashboard_valid_ img {
	width: 50% !important;
}

.dashboard_valid_offer {
	width: 100%;
	text-align: center;
	display: block;
	background: #91919121;
	padding: 15px;
}

.dashboard_valid_offer h5 {
	font-size: 15px;
}

.dashboard_valid_offer button {
	background: transparent;
	border: none;
	font-weight: 600;
	font-size: 14px;
	color: #d61c22;
}

.personal_profile_area {
	width: 100%;
	float: left;
	display: block;
	padding: 15px;
	box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .15);
}

.personal_profile_area h3 button {
	background: transparent;
	border: none;
	color: #f00;
	padding-left: 6px;
}

.personal_profile_area h6 {
	font-weight: 600;
	padding-top: 10px;
	font-size: 14px;
}

.default_shipping_address {
	width: 50%;
	float: left;
	display: block;
	border-right: 1px solid #2222;
	padding-right: 18px;
}

.default_shipping_address:last-child {
	width: 50%;
	float: left;
	display: block;
	border-right: none;
	padding-left: 15px;
}

.default_shipping_address span {
	font-size: 13px;
	color: #777;
}

.default_shipping_address h5 {
	font-weight: 600;
	font-size: 14px;
	padding: 5px 0;
}

.default_shipping_address p {
	font-size: 13px;
}

.MuiTableCell-head {
	background-color: #91919121 !important;
	padding: 8px 15px !important;
}

.MuiTableCell-root {
	padding: 15px !important;
	border: 1px solid #2222;
}

.MuiTableRow-root:nth-child(2n) {
	background-color: #91919121 !important;
}

.myProfile___area .ant-form-item {
	margin-bottom: 12px !important;
}

.myProfile___area .ant-input {
	padding: 10px 11px !important;
}

.myProfile___area .ant-picker {
	padding: 10px 11px !important;
	width: 100% !important;
}

.myProfile___area button {
	width: 48%;
	background: #d61c22 !important;
	border: none;
	height: 40px;
}

.myProfile___area button:last-child {
	float: right;
	background: #4caf50 !important;
}

.edit__btn {
	background: #d61c22;
	color: #fff;
	padding: 5px 20px;
	border: none;
	letter-spacing: .6px;
	border-radius: 2px;
}

/* SELLER BANNER AREA  */
.seller_banner_area {
	width: 100%;
	float: left;
	display: block;
}

.seller_logo {
	width: 150px;
	height: 150px;
	background: #fff;
	border: 4px solid #414141;
	border-radius: 150px;
	line-height: 137px;
	margin-top: -35px;
	margin-left: 13px;
	float: left;
	overflow: hidden;
}

.banner_seller {
	border-radius: 0 0 10px 10px;
}

.seller_name {
	float: left;
	padding: 15px 0 0 15px;
}

.seller_name h3 {
	font-size: 18px;
	font-weight: 600;
	color: rgb(85, 85, 85);
}

.seller_name h5 {
	font-size: 12px;
	color: rgb(117, 117, 117);
	padding: 5px 0;
}

.seller_name p {
	font-size: 14px;
	color: rgb(117, 117, 117);
}

.follow_btn {
	float: right;
	background: #1a6ed8;
	border: none;
	color: #fff;
	padding: 5px 20px;
	border-radius: 5px;
	margin-top: 18px;
	font-weight: 600;
}

.follow_btn svg {
	font-size: 20px;
	margin-right: 5px;
}

.seller_body_width {
	width: 70%;
	margin: auto;
	display: block;
	float: none;
	background: #fff;
}

.seller_body_width .ant-tabs-tab {
	padding: 12px 25px !important;
	margin-top: 5px;
}

/* SELLER FlashSale */
.seller_profile__header {
	width: 100%;
	float: left;
	display: block;
	padding: 20px 0;
}

.seller_profile__header h3 {
	float: left;
	font-size: 30px;
	font-weight: 600;
}

.odometer-block {
	margin-left: 30px;
	font-size: 16px;
	background: #222;
	color: #fff;
	padding: 5px 20px !important;
	border-radius: 2px;
}

element {
	color: #222;
	font-weight: 600;
	font-size: 24px;
	margin-top: 10px;
	float: left;
}

.animate-charcter {
	background-image: linear-gradient(-225deg, #231557 0%, #44107a 29%, #ff1361 67%, #fff800 100%);
	background-size: auto auto;
	background-clip: border-box;
	background-size: 200% auto;
	/* color: #fff; */
	background-clip: text;
	/* text-fill-color: transparent; */
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: textclip 2s linear infinite;
	display: inline-block;
	color: #222;
	font-weight: 600;
	font-size: 24px;
	margin-top: 10px;
}

@keyframes textclip {
	to {
		background-position: 200% center;
	}
}

.ant-menu-submenu-title .ant-menu-item-icon {
	display: none !important;
}

.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title {
	height: 18px;
	line-height: 18px;
}

.ant-menu-vertical>.ant-menu-item {
	height: 20px !important;
	line-height: 20px !important;
}

.ant-menu-submenu-title .ant-menu-item-icon+span {
	margin-left: 0px !important;
}

/* DASHBOARD TWO  */
.banner {
	background: rgba(239, 0, 0, 0.1);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 4px;
	/* margin: 1.5rem 0 0 0; */
	padding: 10px 15px;
}

.banner img {
	height: 100px !important;
	width: 150px;
}

.banner .banner-color {
	color: #c6d6ef !important;
}

.banner .page-content {
	color: #03157b;
}

.page-content {
	margin: .75rem 0;
}

.btn-success {
	color: #fff !important;
	background-color: #36b37e !important;
	border-color: #36b37e !important;
	box-shadow: 0 5px 10px rgba(19, 191, 166, 0.3) !important;
}

.btn-primary {
	color: #fff;
	background-color: #2205bf !important;
	border-color: #2205bf !important;
	box-shadow: 0 5px 10px rgba(34, 5, 191, 0.3);
}

.mr-2 {
	margin-right: 15px;
}

.banner .page-content h3 {
	font-size: 22px;
	font-weight: 600;
	padding-bottom: 3px;
	padding-top: 10px;
}

.banner .page-content p {
	font-size: 16px;
	color: #d61c22;
}

.dashBordMenu_box {
	width: 100%;
	float: left;
	display: block;
}

.dashBordMenu_box ul {
	width: 100%;
	float: left;
	display: block;
	padding: 10px;
	background: #f7d0d0;
	border-top: 1px solid #2222;
}

.dashBordMenu_box ul li {
	width: auto;
	float: left;
	display: block;
	padding-right: 40px;
}

.dashBordMenu_box ul li a {
	color: #5d3333;
	letter-spacing: .6px;
}

.active__box_area {
	width: 100%;
	float: left;
	display: block;
	padding: 15px 20px;
	margin-top: 20px;
	border: 1px solid #2222;
	border-radius: 5px;
}

.active__box_text {
	width: 60%;
	float: left;
	display: block;
}

.active__box_text h3 {
	font-size: 28px;
	padding: 0;
	font-weight: 500;
}

.active__box_chrt {
	width: 40%;
	float: left;
}

.active__box_text h3 {
	font-size: 28px;
	padding: 0;
	font-weight: 600;
	color: #3c4858;
}

.active__box_text p {
	font-weight: 600;
}

.d_edit_btn {
	color: #d61c22;
	margin-left: 5px;
}

.d_address_box {
	font-size: 13px;
	color: #616161;
	letter-spacing: .6px;
	width: 50%;
	float: left;
}

.d_address_box h5 {
	font-size: 16px;
	font-weight: 600;
	padding: 3px 0;
}

.d_address_box p {
	color: #373737;
	font-weight: 500;
}

.css-11mde6h-MuiPaper-root {
	background-color: #fff;
	color: rgba(0, 0, 0, 0.87);
	-webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	border-radius: 4px;
	box-shadow: none !important;
}

.active__box_area .mui_table_dashboard {
	height: 395px;
	overflow: scroll;
}

/* RASPONSIVE MENU  */
.mobile__footer_area {
	background: #fff;
	padding: 10px 0;
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 999;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 2px 10px rgba(0, 0, 0, 0.10);
}

.footer__fixt__box {
	width: auto;
	text-align: center;
	display: block;
}

.footer__fixt__box svg {
	color: #d61c22;
	font-size: 16px;
}

.footer__fixt__box h6 {
	font-size: 9px;
	font-weight: 400;
	letter-spacing: .6px;
}

.three_catagori_box .card {
	width: 100%;
}

.seller__them__ctgry__nme {
	font-size: 14px;
	padding: 10px 16px;
	font-weight: 600;
}

.min_max_p_area {
	width: 100%;
	float: left;
	display: block;
}

.min__p {
	width: auto;
	float: left;
	border: 1px solid #2222;
	padding: 6px 20px;
	font-size: 10px;
}

.max__p {
	width: auto;
	float: right;
	border: 1px solid #2222;
	padding: 6px 20px;
	font-size: 10px;
}

.slider__rng {
	width: 100%;
	float: left;
	display: block;
	border-bottom: none !important;
}

.priceRange__color .MuiSlider-root {
	color: #d61c22 !important;
}

.priceRange__color {
	width: 100%;
	float: left;
	display: block;
	margin-bottom: 15px;
	/* padding: 0 5px; */
}

.s_range__btn {
	float: right;
	background: #d61c22;
	border: none;
	padding: 5px 10px;
	line-height: 20px;
	color: #fff;
}

.down__icon svg {
	font-size: 13px;
}

.disabled__ {
	background: #222;
}

.clear__btn {
	position: absolute;
	right: 20px;
	top: 17px;
	background: transparent;
	border: none;
	color: #222;
	font-size: 14px;
	transition: all .3s;
}

.clear__btn:hover {
	color: #d61c22;
}

.img__width {
	width: 30px;
}

.qty-box svg {
	float: right;
	text-align: right;
	position: absolute;
	right: 0;
	font-size: 20px;
	cursor: pointer;
}

.orderSummary__cart {
	width: 100%;
	float: left;
	display: block;
	padding: 15px;
}

.card-header h5 {
	font-size: 16px;
	border-bottom: 1px solid #2222;
	padding-bottom: 12px;
}

.orderSummary__cart h3 {
	font-size: 18px;
	padding-bottom: 15px;
	font-weight: 600;
}

.orderSummary__cart a {
	background: #d61c22;
	border: none;
	color: #fff;
	padding: 8px 0;
	width: 100%;
	color: #fff !important;
	display: block;
	text-align: center;
}

.add_to_cart__title {
	font-weight: 600;
	font-size: 16px;
	padding-bottom: 25px;
	text-align: center;
}

.address_T_txt {
	width: 100%;
	border: 1px solid #2222;
	height: 80px;
	padding: 15px;
}

.checkout-title h3 {
	font-size: 19px;
	padding-top: 20px;
	padding-left: 28px;
}

.payment_options_title {
	margin-bottom: 0;
	font-weight: 600;
	font-size: 18px;
}

.place__btn__box {
	background: #ee4b50;
	color: #fff;
	padding: 10px 19px;
	border-radius: 5px;
}



#weiboo-load {
	display: -webkit-box;
	display: -ms-flexbox;
	-webkit-box-pack: center;
	-webkit-box-align: center;
	display: -webkit- flex;
	display: -ms- flex;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100vh;
	background: #ffffff;
	position: fixed;
	z-index: 999999;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.cart_preloader {
	display: block;
	margin: 0 auto 1.5em auto;
	width: 8em;
	height: 8em;
}

.pay__list {
	border: 1px solid #2222;
	padding: 10px 15px;
	margin-bottom: 15px;
}

.submit__baynow {
	background: #b61b20;
	border: none;
	color: #fff;
	padding: 6px 29px;
	margin-right: 15px;
	border-radius: 3px;
	margin-top: 15px;
}

.content__buy__block {
	background: #fff;
	-webkit-box-shadow: 0 6px 16px rgba(0, 0, 0, .12);
	box-shadow: 0 6px 16px rgba(0, 0, 0, .12);
	border-radius: 8px;
	padding: 24px 20px;
	margin-bottom: 12px;
}

.content__buy__block__box_one {
	width: 100%;
	/* display: flex; */
	padding-top: 15px;
}

.content__buy__block__box_two {
	padding: 10px 15px;
	border: 1px solid #2222;
	border-radius: 5px;
	margin-top: 17px;
}

.buy_now_order_submit__btn {
	background: #b61b20;
	border: none;
	padding: 6px 20px;
	color: #fff;
	border-radius: 5px;
}

.catagories__hdr__nme {
	padding-top: 10px;
	font-size: 15px;
	cursor: pointer;
	transition: all .3s;
}

.catagories__hdr__nme:hover {
	color: #d61c22;
}

.ant-menu-sub.ant-menu-inline {
	background: #fff !important;
}

.wishlist__add__btnnn {
	width: 100%;
	float: left;
	padding: 14px;
	border: none;
	font-size: 14px;
	margin-top: 10px;
	margin-bottom: 10px;
	border-radius: 5px;
}

.active__box_area .MuiTableCell-root {
	padding: 3px 5px !important;
	border: 1px solid #cdcdcd !important;
}

.theme_form__wishlist {
	background: #fff;
	padding: 15px;
	margin-top: 2px;
}

.add_to_cart__title__wishlist {
	float: left;
	width: 100%;
	text-align: left;
	padding: 0;
	/* width: auto; */
}

.add_to_cart__title__wishlist_button {
	float: right;
	background: transparent;
	border: none;
}

.from_group__ac_creact {
	width: 100%;
	float: left;
	display: block;
	text-align: left !important;
	font-weight: 600;
	margin-bottom: 12px;
}

.from_group__ac_creact label {
	margin-bottom: 8px;
	font-size: 13px;
}

.from_group__ac_creact span {
	color: #f00;
}

.header__ac__name {
	font-size: 21px;
	margin-bottom: 16px;
	font-weight: 600;
}

.creact__account__user__BOx input {
	padding: 9px 15px !important;
	margin: 0px 0 !important;
}

.creact__account__user__BOx .antd-country-phone-input input {
	padding: 0px !important;
}

.creact__account__user__BOx .ant-form-item-control-input-content {
	flex: auto;
	max-width: 100%;
	border: 1px solid #2222 !important;
}

.creact__account__user__BOx .ant-form-item-explain-error {
	color: #ff4d4f;
	text-align: left;
}

#basic_gender {
	border: 'none'
}

.form-box2 .ant-form-item-control-input-content {
	flex: auto;
	max-width: 100%;
	border: none !important;
}

.account__otp_area {
	display: flex;
	justify-content: center;
}

.account_otp_hdr__txt {
	width: 100%;
	text-align: center;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 15px;
}

.account__otp_area input {
	border: 1px solid #2222;
	padding: 5px;
	width: 40px !important;
	height: 40px !important;
}

.otp__acc_otp__btn {
	width: 100%;
	display: flex;
	justify-content: center;
}

.acc_otp__btn {
	background: #d61c22;
	padding: 6px 20px;
	border: none;
	margin-top: 20px;
	border-radius: 5px;
	color: #fff;
	margin-right: 10px;
}

.otp__Timer__resend_button {
	text-align: center;
	margin-top: 15px;
}

.otp__Timer__resend_button span {
	/* background: #222; */
	border: none;
	padding: 5px 10px;
	font-size: 14px;
	border-radius: 3px;
	transition: all .3s;
	/* color: "#fff" */
}

.otp__Timer__resend_button span:hover {
	/* background: #52c41a !important; */
}

.address_change_btn {
	background: #d61c22;
	border: none;
	padding: 5px 20px;
	color: #fff;
	border-radius: 5px;
}

.address__change__from__area .ant-modal-footer {
	display: none;
}

.hedaer_medial_area .ant-dropdown-menu-item {
	padding: 10px 12px !important;
}

.header_dropdown_list {
	margin-right: 6px;
	font-size: 18px;
}

.exta_input_fill .ant-input {
	width: 61%;
}

.phone__add_icon {
	position: absolute;
	right: 7px;
	top: 7px;
	background: #2222;
	width: 30px;
	height: 30px;
	padding: 8px;
	z-index: 999;
	cursor: pointer;
}

.ant-input:focus,
.ant-input-focused {
	/* border-color: #fff !important; */
	box-shadow: none !important;
}

.euser_profile_modal_area .ant-modal-footer {
	display: none;
}

.error__data__phone {
	width: 100%;
	text-align: center;
	padding-top: 26px;
	font-size: 14px;
}

.add_to_cart .cart-inner .cart_media ul.cart_product .qty-minus:hover,
.add_to_cart .cart-inner .cart_media ul.cart_product .qty-plus:hover {
	background: #2222 !important;
}

.drop__area .ant-dropdown-menu-item {
	padding: 10px 12px !important;
}

.drop__area a {
	color: #222 !important;
}

.phone__add_icon_email {
	position: absolute;
	top: 37px;
}

.catagore__Box_banner .rm-hero-slider {
	height: 60vh !important;
}

.logo__icon__footer {
	position: absolute;
	width: 30px;
	left: 46%;
	top: -30px;
	width: 60px;
	height: 60px;
	background: #fff;
	border: 1px solid #2222;
	padding: 10px;
	border-radius: 50px;
	padding-top: 20px;
	cursor: pointer;
}

.logo__icon__footer img {
	width: 100%;
}

.drawer__menu__rasponsive__area .ant-menu-submenu-title {
	height: 23px !important;
	line-height: 23px !important;
}

.footer__fixt__box {
	display: none;
}

.header_search {
	display: block;
}

.movlile__rasponsive {
	display: none !important;
}

.movile__rspnsive__Header_view {
	display: none;
}

.creact__new__account__btn {
	display: none;
}

.order__details__area {
	width: 100%;
	float: left;
	display: block;
	padding: 30px;
	background: #2222;

}

.box__one__order__details {
	background: #fff;
	padding: 15px;
	width: 80%;
	display: table;
	margin: auto;
	float: l;
	border-radius: 6px;
}

.box__one__order__details p {
	float: left;
}

.box__one__order__details h3 {
	float: right;
	font-size: 18px;
}

.box__one__order__details h5 {
	float: left;
	font-size: 15px;
	padding-left: 15px;
	width: 33%;
}

.box__one__order__details__last {
	background: #fff !important;
	width: 50%;
	float: left;
	/* padding: 20px; */
	border-radius: 5px;
	/* margin-left: 20px; */
}

.box__one__order__details__last_h5 {
	width: 100% !important;
	padding-left: 0px !important;
	margin-top: 15px;
	border-top: 1px solid #2222;
	padding-top: 8px;
	padding-bottom: 8px;
}

.order__imgesss {
	width: 50px;
}

.box__one__order__details_h2 {
	width: 20% !important;
}

.box__one__order__details_h5_10 {
	width: 10% !important;
}

.box__one__order__details_h5_15 {
	width: 15% !important;
}

.new_box_order____data {
	width: 60% !important;
	background: transparent !important;
	display: flex
}

.dddddddd {
	width: 80%;
	margin: auto;
	display: block;
}

.logo__icon__footer__new {
	width: 45px;
	height: 45px;
	margin: auto;
	margin-top: -28px;
	background: #fff;
	padding: 9px;
	border-radius: 30px;
	border: 1px solid #2222;
}

.logo__icon__footer__new img {
	width: 100%;
}

.forget___area {
	width: 50%;
	background: #fff;
	padding: 30px;
	margin: auto;
	display: flex;
}

.drawer__menu__rasponsive__area a {
	color: #222 !important;
}

.drawer__menu__rasponsive__area_2 {
	height: 26px;
	padding-left: 70px !important;
	color: #2222;
	margin-bottom: 2px !important;
}

.header_search .ant-input-group>.ant-input:first-child {
	border-top-left-radius: 30px !important;
	border-bottom-left-radius: 30px !important;
}

.catagori__height__area {
	height: 420px;
	overflow: scroll;
}

.catagori__height__area::-webkit-scrollbar {
	display: none;
}

.catagori__height__area {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.addrees_book_hedaer {
	width: 100%;
	float: left;
	display: block;
	padding: 30px;
	background: #fff;
}

.addrees_book_box {
	width: 100%;
	float: left;
	display: block;
	padding: 30px;
	background: #fff;
	/* border: 1px solid #2222; */
}

.addrees_book_hedaer p {
	float: left;
	width: 100%;
	font-weight: 600;
}

.addrees_book_hedaer p span {
	float: right;
	font-weight: 600;
}

.addrees_book_box p {
	float: left;
	width: 100%;
	padding-bottom: 10px;
}

.addrees_book_box p span {
	float: right;
	font-weight: 600;
	color: #f00;
	cursor: pointer;
}

.address__delect {
	float: right;
	width: 25px;
	height: 25px;
	background: #f00;
	color: #fff;
	padding: 5px;
	border-radius: 20px;
}

.loader2 {
	position: initial;
}

.outer,
.middle,
.inner {
	border: 3px solid transparent;
	border-top-color: #ffffff;
	border-right-color: #ffffff;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
}

.outer {
	width: 4em;
	height: 4em;
	margin-left: -1.75em;
	margin-top: -1.75em;
	animation: spin 2s linear infinite;
}

.middle {
	width: 2.5em;
	height: 2.5em;
	margin-left: -1.05em;
	margin-top: -1.05em;
	animation: spin 1.75s linear reverse infinite;
}

.inner {
	width: 1em;
	height: 1em;
	margin-left: -0.4em;
	margin-top: -0.4em;
	animation: spin 1.5s linear infinite;
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

.addrees_book_box h5 {
	background: rgb(250, 173, 20);
	float: left;
	font-size: 13px;
	padding: 3px 10px;
	border-radius: 3px;
	color: #fff;
}

.default__pages {
	width: 100%;
	float: left;
	display: block;
	padding: 15px;
}

.default__pages__contant h3 {
	width: 100%;
	font-size: 17px;
	font-weight: 600;
	margin-bottom: 5px;
}

/* .header__exta__class {
	background: #ffd1d3;
	height: 40px;
	color: #fff !important;
	padding-left: 16px;
} */