
.top_header_animishon .snowflake {
    position: absolute;
    transform: translate(-50%, -50%);
    opacity: 1;
    color: snow;
    font-family: "Georgia", serif;
    line-height: 0;
    animation: spin 20s linear infinite, glow 2s linear infinite,
      drop 10s cubic-bezier(0, 0, 0.92, -0.125);
    user-select: none;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0);
    }
  
    100% {
      transform: rotate(1turn);
    }
  }
  
  @keyframes glow {
    50% {
      text-shadow: 0 0 3rem rgba(255, 255, 255, 0.2);
    }
  }
  
  @keyframes drop {
    from {
      top: 0;
    }
  
    to {
      top: 1500px;
    }
  }
  
  .top_header_animishon .snowflake s {
    display: inline-block;
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 0;
    transform-origin: center top;
    font-family: monospace;
    width: 50px;
    height: 25px;
    font-size: 50px;
    text-decoration: none;
    letter-spacing: -0.5rem;
  }
  
  .top_header_animishon .snowflake s:nth-child(2) {
    transform: rotate(0.2turn);
  }
  
  .top_header_animishon .snowflake s:nth-child(3) {
    transform: rotate(0.4turn);
  }
  
  .top_header_animishon .snowflake s:nth-child(4) {
    transform: rotate(0.6turn);
  }
  
  .top_header_animishon .snowflake s:nth-child(5) {
    transform: rotate(0.8turn);
  }
  
  #container_animishon {
    width: 100%;
    height: 100%;
    background: url("../images/topbanner.png");
    height: 60px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    filter: drop-shadow(0 0 10px white);
    object-fit: cover;
  }
  
 .top_header_animishon span{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: #fff;
    font-size: 5vh;
  }
  
  @function random_range($min, $max) {
    $rand: random();
    $random_range: $min + floor($rand * (($max - $min) + 1));
    @return $random_range;
  }
  
 .top_header_animishon .snow {
    $total: 200;
    position: absolute;
    width: 10px;
    height: 10px;
    background: white;
    border-radius: 50%;
  
    @for $i from 1 through $total {
      $random-x: random(1000000) * 0.0001vw;
      $random-offset: random_range(-100000, 100000) * 0.0001vw;
      $random-x-end: $random-x + $random-offset;
      $random-x-end-yoyo: $random-x + ($random-offset / 2);
      $random-yoyo-time: random_range(30000, 80000) / 100000;
      $random-yoyo-y: $random-yoyo-time * 100vh;
      $random-scale: random(10000) * 0.0001;
      $fall-duration: random_range(10, 30) * 1s;
      $fall-delay: random(30) * -1s;
  
      &:nth-child(#{$i}) {
        opacity: random(10000) * 0.0001;
        transform: translate($random-x, -10px) scale($random-scale);
        animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
      }
  
      @keyframes fall-#{$i} {
        #{percentage($random-yoyo-time)} {
          transform: translate($random-x-end, $random-yoyo-y) scale($random-scale);
        }
  
        to {
          transform: translate($random-x-end-yoyo, 100vh) scale($random-scale);
        }
      }
    }
  }